import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  // base: '/bigbook/',
  routes: [
    {
      path: '/bigbook/index',
      name: 'index',
      component: (resolve) => require(['../views/index.vue'], resolve),
      meta: {
        title: "首页"
      }
    }, {
      path: '/bigbook/login',
      name: 'login',
      component: (resolve) => require(['../views/login/login.vue'], resolve),
      meta: {
        title: "登录"
      }
    }, {
      path: '/bigbook/activity',
      name: 'activity',
      component: (resolve) => require(['../views/activity/activity.vue'], resolve),
      meta: {
        title: "活动中心"
      }
    }, {
      path: '/bigbook/copyright',
      name: 'copyright',
      component: (resolve) => require(['../views/copyright/copyright.vue'], resolve),
      meta: {
        title: "版权合作"
      }
    }, {
      path: '/bigbook/about',
      name: 'about',
      component: (resolve) => require(['../views/about/about.vue'], resolve),
      meta: {
        title: "关于我们"
      }
    }, {
      path: '/bigbook/news/detail/:id',
      name: 'news/detail',
      component: (resolve) => require(['../views/news/detail.vue'], resolve),
      meta: {
        title: "新闻动态详情"
      }
    }, {
      path: '/bigbook/cases/detail/:id',
      name: 'cases/detail',
      component: (resolve) => require(['../views/cases/detail.vue'], resolve),
      meta: {
        title: "案例详情"
      }
    }, {
      path: '/bigbook/authors/detail/:id',
      name: 'authors/detail',
      component: (resolve) => require(['../views/authors/detail.vue'], resolve),
      meta: {
        title: "名家详情"
      }
    }, {
      path: '/bigbook/publish/detail/:id',
      name: 'publish/detail',
      component: (resolve) => require(['../views/publish/detail.vue'], resolve),
      meta: {
        title: "出版社详情"
      }
    }, {
      path: '*',
      redirect: '/bigbook/index'
    }]
});
