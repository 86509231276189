import Vue from 'vue';
import App from './App.vue';
import router from './router'; // 路由配置
import store from './store'; // vuex
import iView from 'iview'; // ui 框架
import 'iview/dist/styles/iview.css'; // ui 框架CSS
import '@/filter'; // 过滤器
import '@/flyio'; // jajax
import './assets/style/common.less';

Vue.use(iView, {});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h:any) => h(App),
}).$mount('#app');
