



















import { Component, Vue, Watch } from "vue-property-decorator";
import Breadcrumb from "@/components/breadcrumb.vue";
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import { Mutation } from "vuex-class";
import utils from "@/utils";

@Component({
  components: { Breadcrumb, Header, Footer }
})
export default class App extends Vue {
  @Mutation private SET_ROUTE_NAME: any;

  @Watch("$route", { deep: true })
  private routeChange(to: any, from: any) {
    this.SET_ROUTE_NAME(to.name);
    utils.backTop();
  }
}
