


























import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class HeaderNav extends Vue {
  private theme: string = "dark";
  private name: string | null |undefined = "";

  private created() {
    this.name = this.$route.name;
  }

  @Watch("$route", { deep: true })
  private routechange(to: any, from: any) {
    this.name = to.name;
  }
}
