import Vue from 'vue';
import Vuex from 'vuex';
import statex from './state';
import mutationsx from './mutations';
import gettersx from './getters';
import actionsx from './actions';
Vue.use(Vuex);

export default new Vuex.Store({
  state: statex,
  getters: gettersx,
  mutations: mutationsx,
  actions: actionsx
});
