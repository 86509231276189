import { RootStateTypes } from './types';
import { ActionTree } from 'vuex';

// 异步执行
const actions: ActionTree<RootStateTypes, any> = {
    SET_ROUTE_NAME_ASYN({ commit, state }, data: boolean) {
        commit('SET_ROUTE_NAME', data);
    }
};
export default actions;
