import Vue from 'vue';
import localforage from 'localforage';

/**
 * 不足10 补 0
 * @param num // 被补数
 */
const supplementZeroFn = (num: any) => {
  num = Number(num);
  return (num < 10 ? '0' + num : num);
};
const utils: any = { // 工具包
  /**
   * 格式化时间戳
   * @param val // 数据
   * @param format // 格式
   */
  formatTimeFn(val: any, format: string = 'YYYY-MM-DD hh:mm:ss'): string {
    let Dates = null;
    format = format || 'YYYY-MM-DD hh:mm:ss';
    if (!val) {
      return '无';
    }
    if (val instanceof Object) {
      Dates = new Date(val);
    } else if (Number(val)) {
      val = (val += '').length === 13 ? Number(val) : Number(val) * 1000;
      Dates = new Date(val);
    } else {
      return val;
    }
    const year: any = Dates.getFullYear(); // 年
    const month: any = supplementZeroFn(Dates.getMonth() + 1); // 月
    const date: any = supplementZeroFn(Dates.getDate()); // 日
    const hour: any = supplementZeroFn(Dates.getHours()); // 时
    const minute: any = supplementZeroFn(Dates.getMinutes()); // 分
    const second: any = supplementZeroFn(Dates.getSeconds()); // 秒
    format = format.replace(/YYYY/, year);
    format = format.replace(/MM/, month);
    format = format.replace(/DD/, date);
    format = format.replace(/hh/, hour);
    format = format.replace(/mm/, minute);
    format = format.replace(/ss/, second);
    return format;
  },
  backTop() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  supplementZeroFn,
  getClientWidth(): number {
    let clientWidth = 0;
    if (document.compatMode === "CSS1Compat") {
      clientWidth = document.documentElement.clientWidth;
    } else {
      clientWidth = document.body.clientWidth;
    }
    return clientWidth;
  },
  getClientHeight(): number {
    let clientHeight = 0;
    if (document.compatMode === "CSS1Compat") {
      clientHeight = document.documentElement.clientHeight;
    } else {
      clientHeight = document.body.clientHeight;
    }
    return clientHeight;
  },
  getScrollHeight(): number {
    let scrollHeight = 0;
    let bodyScrollHeight = 0;
    let documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
    return scrollHeight;
  },
  setBodyOverflow(isShow: boolean, type?: string) {
    const overflow = isShow ? "auto" : "hidden";
    let bodyStyle;
    if (document.body) {
      bodyStyle = document.body.style;
    } else if (document.documentElement) {
      bodyStyle = document.documentElement.style;
    } else {
      bodyStyle = document.getElementsByTagName("body")[0].style;
    }
    if (type === "x") {
      bodyStyle.overflowX = overflow;
    } else if (type === "y") {
      bodyStyle.overflowY = overflow;
    } else {
      bodyStyle.overflow = overflow;
    }
    // bodyStyle.position = isShow ? "" : "fixed";
  },
  isEmpty(value: any): boolean {
    if (value == null || typeof value === 'string' && value.length === 0) {
      return true;
    } else if (utils.isObject(value)) {
      if (utils.isArray(value) && this.length === 0) {
        return true;
      } else if (utils.isJsonString(value) && JSON.stringify(value) === "{}") {
        return true;
      }
    }
    return false;
  },
  isNotEmpty(value: any): boolean {
    return !this.isEmpty(value);
  },
  isArray(value: any): boolean {
    return Array.isArray(value);
  },
  isObject(value: any): boolean {
    return typeof value === 'object' && !this.isArray(value);
  },
  isSubstring(value: string, len: number): any {
    if (value.length > len) {
      value = value.substring(0, len);
    }
    return value;
  },
  isJsonString(value: any) {
    try {
      if (JSON.stringify(value) === "{}") {
        return true;
      }
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  },
  convertTwoDmlArray(arr: [], size: number): any[] {// 数组转二维数组
    if (!arr || arr.length === 0 || !size || size <= 0) {
      return [];
    } else if (size >= arr.length) {
      return [arr];
    } else {
      let index = 0;
      const result: any[] = [];
      for (let i = 0; i < arr.length; i++) {
        if (i === 0) {
          result[0] = [];
        } else if (i % size === 0) {
          result[++index] = [];
        }
        result[index].push(arr[i]);
      }
      return result;
    }
  },
  arrayToTwoArray(array: any, num: number) {
    const result = [];
    if (array && array.length > 0) {
      if (array.length <= num) {
        result[0] = array;
      } else {
        const len = array.length;
        const lineNum = len % num === 0 ? len / num : Math.floor(len / num + 1);
        for (let i = 0; i < lineNum; i++) {
          result.push(array.slice(i * num, i * num + num));
        }
      }
    }
    return result;
  },
  goElevatorPage(id: any) {
    let evtObj;
    const thisPage = (id as HTMLDivElement);
    const elevatorDiv = thisPage.getElementsByClassName("ivu-page-options-elevator");
    if (elevatorDiv && elevatorDiv.length > 0) {
      const pageInput = elevatorDiv[0].getElementsByTagName("input")[0];
      if ((window as any).KeyEvent) {// firefox 浏览器下模拟事件
        evtObj = document.createEvent('KeyEvents');
        (evtObj as any).initKeyEvent("keyup", true, true, window, true, false, false, false, 13, 0);
      } else {// chrome 浏览器下模拟事件
        evtObj = document.createEvent('UIEvents');
        evtObj.initEvent("keyup", true, true);
        delete (evtObj as any).keyCode;
        if (typeof (evtObj as any).keyCode === "undefined") {// 为了模拟keycode
          Object.defineProperty(evtObj, "keyCode", { value: 13 });
        } else {
          (evtObj as any).key = String.fromCharCode(13);
        }
      }
      pageInput.dispatchEvent(evtObj);
    }
  }
};
window.$utils = utils; // 添加到 Vue 原型
Vue.$utils = utils;
window.$localforage = localforage; // 添加到 Vue 原型
Vue.$localforage = localforage;

export default utils;
