








import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class BreadCrumb extends Vue {
  private levelList: any[] = [{ path: "/index", name: "index", meta: { title: "首页" } }];

  @Watch("$route", { deep: true })
  private routeChange(to: any, from: any) {
    // this.getBreadcrumb();
    // 存在公用页面，需单独处理
    if (to.name.indexOf("/") === -1) {
      if (this.levelList.length > 1) {
        this.levelList = this.levelList.slice(0, 1);
      }
      if (to.name !== "index") {
        this.levelList.push(to);
      }
    } else {
      if (this.levelList.filter((item) => item.meta.title === to.meta.title).length === 0) {
        this.levelList.push(to);
      }
    }
  }

  private getBreadcrumb() {
    const matched:any = this.$route.matched.filter((item) => item.name).filter((item) => item.meta.title);
    const first = matched[0];
    if (first && first.name !== "index") {
      this.levelList = [{ path: "/index", meta: { title: "首页" } }].concat(matched);
    } else {
      this.levelList = matched;
    }
  }
}
