


















import { Component, Vue, Watch } from "vue-property-decorator";
// import { Getter } from "vuex-class";
import HeaderNav from "./header-nav.vue";

@Component({ components: { HeaderNav } })
export default class Header extends Vue {
  private scroll: boolean = false;
  // @Getter private routeName: any;
  // @Watch("routeName", { deep: true })
  // private routeNameChange() {
  //   console.log(this.routeName);
  // }

  private mounted() {
    this.$forceUpdate();
    window.addEventListener("scroll", this.handleScroll, true);
  }

  private handleScroll() {
    let scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }
    if (scrollTop > 8) {
      this.scroll = true;
    } else {
      this.scroll = false;
    }
  }
}
