import { RootStateTypes } from './types';
import { MutationTree } from 'vuex';

// 同步执行
const mutations: MutationTree<RootStateTypes> = {
    SET_ROUTE_NAME(state: RootStateTypes, data: string) {
        state.routeName = data;
    }
};
export default mutations;
